.tabview-detail {
  .tabview-custom {
    i, span {
      vertical-align: middle;
    }

    span {
      margin: 0 .5rem;
    }
  }

  .p-button {
    margin-right: .25rem;
  }

  .p-tabview p {
    line-height: 1.5;
    margin: 0;
  }
}